const stringsComuns = {
    atencao: 'Atention',
    cancelar: 'Cancel',
    campoObrigatorio: 'Required field',
    confirmar: 'Confirm',
    email: 'E-mail',
    emailInvalido: 'Invalid e-mail',
    fechar: 'Close',
    limpar: 'Clear',
    nomeSistema: 'Sistema Integrado Catarinense de Telemedicina e Telessaúde',
    ok: 'OK',
    senha: 'Password',
    usuario: 'User'
};

export default {
    LoginForm: {
        nomeSistema: stringsComuns.nomeSistema
    },
    AcessoSistema: {
        acessoSistema: 'Restricted area',
        esqueceuSenha: 'Forgot your password?',
        entrarSistema: 'Log In',
        naoPossuiAcesso: 'Sign in',
        usuario: stringsComuns.usuario,
        senha: stringsComuns.senha
    }
}
