import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { SttContainer } from '@stt-componentes/core';
import {
    setUsuarioAtendente,
    setEnviarMensagem,
    setMensagemEnviada,
    setUsuarioDesconetado,
    setAtualizarAnexos,
    setEnviarSignalAtualizarAnexos,
    setRealizarChamadaVideo,
    setEntrarSalaVideo,
    setChatOpen
} from '../../reducers/actions/helpdesk';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChatAtendido from './chat-atendido';
import ModalUsuarioOnline from '../../componentes/modal-usuario-online';
import Anexos from '../../componentes/anexos/lista-anexos';
import Video from '../../componentes/video';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        maxWidth: '100%',
        padding: 0
    },
}));

const TeleatendimentoPaciente = ({ user, usuarioConversa, setUsuarioAtendente, setEnviarMensagem, setMensagemEnviada, mensagemEnviar, setEntrarSalaVideo, setChatOpen,
    setUsuarioDesconetado, setAtualizarAnexos, setEnviarSignalAtualizarAnexos, enviarSignalAtualizarAnexos, setRealizarChamadaVideo, realizarChamadaVideo }) => {
    const classes = useStyles();

    const [idSocket, setIdSocket] = useState('');
    const [usuarioOnline, setUsuarioOnline] = useState(false);

    const socket = useRef();

    useEffect(() => {
        if (enviarSignalAtualizarAnexos) {
            socket.current.emit(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS, {
                idSocket: usuarioConversa.idSocket
            });

            setEnviarSignalAtualizarAnexos();
        }
    }, [enviarSignalAtualizarAnexos]);

    useEffect(() => {
        const userToSend = {
            name: user.name,
            userId: user.userId,
            encounterId: user.encounterId,
            partition: user.partition,
            ehAtendente: false
        };

        const options = { query: userToSend };
        if (global.gConfig.url_base_socket_path != '') {
            options.path = global.gConfig.url_base_socket_path;
        }
        socket.current = io.connect(global.gConfig.url_base_socket, options);

        socket.current.on(EVENTOS_SOCKET.ID_SOCKET, (id) => {
            // console.log('================================');
            console.log('é paciente');
            // console.log('voltou com id', id);
            setIdSocket(id);
        });

        socket.current.on(EVENTOS_SOCKET.ATENDENTE_CONECTADO, (dadosAtendente) => {
            setUsuarioAtendente(dadosAtendente);
        });

        socket.current.on(EVENTOS_SOCKET.SIGNAL_ENTRAR_SALA_V4H, () => {
            setRealizarChamadaVideo(true);
            setEntrarSalaVideo();
        });

        socket.current.on(EVENTOS_SOCKET.SIGNAL_FECHAR_SALA_V4H, () => {
            setChatOpen();
            setRealizarChamadaVideo(false);
        });

        socket.current.on(EVENTOS_SOCKET.MENSAGEM_ENVIADA, (dados) => {
            setMensagemEnviada(dados);
        });

        socket.current.on(EVENTOS_SOCKET.USUARIO_DESCONETADO, () => {
            setUsuarioDesconetado();
            setMensagemEnviada(null);
            setRealizarChamadaVideo(false);
        });

        //Evento recebido quando o usuário já está conectado em outra aba/navegador
        socket.current.on(EVENTOS_SOCKET.USUARIO_ONLINE, () => {
            setUsuarioOnline(true);
        });

        socket.current.on(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS, () => {
            setAtualizarAnexos();
        });

    }, []);

    useEffect(() => {
        if (mensagemEnviar) {
            const dados = {
                destinatario: usuarioConversa.idSocket,
                author: {
                    name: user.name,
                    id: user.userId
                },
                message: mensagemEnviar,
                timestamp: new Date().getTime()
            };
            socket.current.emit(EVENTOS_SOCKET.ENVIAR_MENSAGEM, dados);
            setEnviarMensagem();
            setMensagemEnviada(dados);
        }
    }, [mensagemEnviar]);

    return (
        <SttContainer className={classes.root}>
            <CssBaseline />
            {
                !usuarioOnline ?
                    (
                        <>
                            <ChatAtendido />
                            {
                                realizarChamadaVideo ? <Video /> : null
                            }
                            <Anexos />
                        </>
                    )
                    :
                    <ModalUsuarioOnline online={usuarioOnline} />
            }
        </SttContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        usuarioConversa: state.helpdesk.usuarioConversa,
        mensagemEnviar: state.helpdesk.mensagemEnviar,
        enviarSignalAtualizarAnexos: state.helpdesk.enviarSignalAtualizarAnexos,
        realizarChamadaVideo: state.helpdesk.realizarChamadaVideo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsuarioAtendente: (usuario) => dispatch(setUsuarioAtendente(usuario)),
        setMensagemEnviada: (mensagem) => dispatch(setMensagemEnviada(mensagem)),
        setEnviarMensagem: () => dispatch(setEnviarMensagem(null)),
        setUsuarioDesconetado: () => dispatch(setUsuarioDesconetado()),
        setAtualizarAnexos: () => dispatch(setAtualizarAnexos(true)),
        setEnviarSignalAtualizarAnexos: () => dispatch(setEnviarSignalAtualizarAnexos(false)),
        setRealizarChamadaVideo: (chamada) => dispatch(setRealizarChamadaVideo(chamada)),
        setChatOpen: () => dispatch(setChatOpen(true)),
        setEntrarSalaVideo: () => dispatch(setEntrarSalaVideo(true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeleatendimentoPaciente);