import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import Functions from '../common/Functions';
import { setUser } from '../reducers/actions';
import Atendente from './atendente';
import Paciente from './paciente';
import {
    SttCircularProgress,
    SttText
} from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        placeContent: 'stretch space-between',
        alignItems: 'stretch',
    },
    loading: {
        position: 'absolute',
        left: '50%',
        top: '35%',
        textAlign: 'center',
    },
}));

const TeleAtendimento = ({ setUser }) => {
    const classes = useStyles();

    const { token } = useParams();
    const history = useHistory();
    const [validationDone, setValidationDone] = useState(false);
    const [isPractitioner, setIsPractitioner] = useState(false);

    useEffect(() => {
        const backendBaseUrl = global.gConfig.url_base_backend;
        // Verifica validade do token

        axios.get(`${backendBaseUrl}/${token}`)
            .then(function (response) {
                const { data } = response;

                if (Functions.isPractitioner(data.role)) {
                    setIsPractitioner(true);
                }
                setUser(data);
                setValidationDone(true);   
            })
            .catch(function (error) {
                /*eslint-disable */
                console.log(error);
                /* eslint-enable */
                if (error.response) {
                    switch (error.response.status) {
                        case HttpStatus.UNAUTHORIZED:
                        case HttpStatus.BAD_REQUEST:
                            history.replace('/nao-autorizado');
                            break;
                        case HttpStatus.LOCKED:
                            history.replace('/aguarde');
                            break;
                        default:
                            history.replace('/erro'); // TODO criar componente
                            break;
                    }
                } else {
                    history.replace('/erro'); // TODO criar componente
                }
            });
    }, []);

    return validationDone ? (
        isPractitioner ? (<Atendente />) : (<Paciente />)
    ) : (
        <div className={classes.loading}>
            <SttCircularProgress />
            <SttText variant="body1">
                Aguarde...
            </SttText>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user))
    };
};

export default connect(null, mapDispatchToProps)(TeleAtendimento);
