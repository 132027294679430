export default {
    INICIAR_ATENDIMENTO: 'INICIAR_ATENDIMENTO',
    FINALIZAR_ATENDIMENTO: 'FINALIZAR_ATENDIMENTO',
    USUARIO_ATENDENTE: 'USUARIO_ATENDENTE',
    USUARIO_DESCONECTADO: 'USUARIO_DESCONECTADO',
    USUARIO_JA_ONLINE: 'USUARIO_JA_ONLINE',
    ENVIAR_MENSAGEM: 'ENVIAR_MENSAGEM',
    MENSAGEM_ENVIADA: 'MENSAGEM_ENVIADA',
    OPEN_ENVIO_ANEXOS: 'OPEN_ENVIO_ANEXOS',
    ATUALIZAR_LISTA_ANEXOS: 'ATUALIZAR_LISTA_ANEXOS',
    ENVIAR_SIGNAL_ATUALIZAR_ANEXOS: 'ENVIAR_SIGNAL_ATUALIZAR_ANEXOS',
    CLOSE_ENVIO_ANEXOS: 'CLOSE_ENVIO_ANEXOS',
    ENVIAR_SIGNAL_ENTRAR_SALA_V4H: 'ENVIAR_SIGNAL_ENTRAR_SALA_V4H',
    ENVIAR_SIGNAL_FECHAR_SALA_V4H: 'ENVIAR_SIGNAL_FECHAR_SALA_V4H',
    ABRIR_FECHAR_ANEXOS: 'ABRIR_FECHAR_ANEXOS',
    ENTRAR_SALA_V4H: 'ENTRAR_SALA_V4H',
    REALIZAR_CHAMADA_VIDEO: 'REALIZAR_CHAMADA_VIDEO',
    ABRIR_FECHAR_CHAT: 'ABRIR_FECHAR_CHAT'
};