import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import translate from '../../componentes/translate';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import {
    SttInput,
    SttContainer,
    SttEmojiPicker,
} from '@stt-componentes/core';
import { setEnviarMensagem } from '../../reducers/actions/helpdesk';

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        top: 'auto',
        bottom: 0
    },
    inputBase: {
        ...theme.palette.input_base
    },
    iconButton: {
        marginLeft: '5px',
        ...theme.palette.icon_button
    },
}));

const ChatBottom = ({ strings, setEnviarMensagem, usuarioConversa, setChatAtivo }) => {
    const classes = useStyles();

    const [mensagem, setMensagem] = useState('');

    const handleChangeMensagem = event => {
        setMensagem(event.target.value);
    };

    const tratarTeclaEnter = event => {
        if (!mensagem || mensagem == '') {
            return;
        }
        if (event.key == 'Enter') {
            setEnviarMensagem(mensagem);
            setChatAtivo(0);
            setMensagem('');
        }
    };

    const setMensagemEmoji = (emojiObject) => {
        setMensagem(`${mensagem} ${emojiObject.emoji}`);
    };

    return (
        <SttContainer>
            <AppBar position="absolute" className={classes.bottomBar} >
                <Toolbar>
                    <SttInput label={strings.digiteMensagem}
                        fullWidth={true}
                        className={classes.inputBase}
                        inputProps={{ 'aria-label': 'mensagem' }}
                        disabled={!usuarioConversa}
                        onChange={handleChangeMensagem}
                        onKeyPress={tratarTeclaEnter}
                        value={mensagem}
                    />
                    {/* <SttEmojiPicker selecionarEmoji={strings.selecionarEmoji}
                        disabled={!usuarioConversa}
                        setMensagemEmoji={setMensagemEmoji} /> */}
                    {
                        usuarioConversa ? (
                            <Tooltip title={strings.enviarMensagem}>
                                <IconButton disableRipple={true}
                                    className={classes.iconButton}
                                    edge="end"
                                    onClick={() => {
                                        if (!mensagem || mensagem == '') {
                                            return;
                                        }
                                        setEnviarMensagem(mensagem);
                                        setChatAtivo(0);
                                        setMensagem('');
                                    }}>
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton className={classes.iconButton}
                                edge="end"
                                disabled={true}>
                                <SendIcon />
                            </IconButton>
                        )
                    }
                </Toolbar>
            </AppBar>
        </SttContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        usuarioConversa: state.helpdesk.usuarioConversa,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEnviarMensagem: (mensagem) => dispatch(setEnviarMensagem(mensagem)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('ChatBottom')(ChatBottom));