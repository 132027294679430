import TYPES from '../types/helpdesk';

export const setIniciarAtendimento = (usuario) => {
    return {
        type: TYPES.INICIAR_ATENDIMENTO,
        payload: { usuario }
    };
}
export const setFinalizarAtendimento = (finalizar) => {
    return {
        type: TYPES.FINALIZAR_ATENDIMENTO,
        payload: { finalizar }
    };
}

export const setFilesOpen = (open) => {
    return {
        type: TYPES.ABRIR_FECHAR_ANEXOS,
        payload: { open }
    };
}

export const setUsuarioAtendente = (usuario) => {
    return {
        type: TYPES.USUARIO_ATENDENTE,
        payload: { usuario }
    };
}

export const setUsuarioDesconetado = () => {
    return {
        type: TYPES.USUARIO_DESCONECTADO,
        payload: {}
    };
}

export const setEnviarMensagem = (mensagem) => {
    return {
        type: TYPES.ENVIAR_MENSAGEM,
        payload: { mensagem }
    };
}

export const setMensagemEnviada = (mensagem) => {
    return {
        type: TYPES.MENSAGEM_ENVIADA,
        payload: { mensagem }
    };
}

export const setAtualizarAnexos = (atualizarListaAnexos) => {
    return {
        type: TYPES.ATUALIZAR_LISTA_ANEXOS,
        payload: { atualizarListaAnexos }
    };
}

export const setEnviarSignalAtualizarAnexos = (enviarSignalAtualizarAnexos) => {
    return {
        type: TYPES.ENVIAR_SIGNAL_ATUALIZAR_ANEXOS,
        payload: { enviarSignalAtualizarAnexos }
    };
}

export const setNotificarV4h = (notifica) => {
    return {
        type: TYPES.ENVIAR_SIGNAL_ENTRAR_SALA_V4H,
        payload: { notifica }
    };
}

export const setFecharV4h = (notifica) => {
    return {
        type: TYPES.ENVIAR_SIGNAL_FECHAR_SALA_V4H,
        payload: { notifica }
    };
}

export const setEntrarSalaVideo = (entrar) => {
    return {
        type: TYPES.ENTRAR_SALA_V4H,
        payload: { entrar }
    };
}

export const setChatOpen = (open) => {
    return {
        type: TYPES.ABRIR_FECHAR_CHAT,
        payload: { open }
    };
}

export const setRealizarChamadaVideo = (chamada) => {
    return {
        type: TYPES.REALIZAR_CHAMADA_VIDEO,
        payload: { chamada }
    };
}