import React from 'react';
import translate from './translate';
import {
    SttModal,
    SttText
} from '@stt-componentes/core';

const ModalUsuarioOnline = ({ strings, online }) => {

    return (
        <SttModal
            title={strings.atencao}
            open={online}
            children={
                <SttText variant="body1">
                    {strings.jaOnline}
                </SttText>
            }
        />
    );
};

export default translate('UsuarioOnline')(ModalUsuarioOnline);