
const stringsComuns = {
    alterarMeusDados: 'Alterar meus dados',
    atencao: 'Atenção',
    campoObrigatorio: 'Campo obrigatório',
    campoInvalido: 'Inválido',
    cancelar: 'Cancelar',
    confirmar: 'Confirmar',
    cpf: 'CPF',
    cpfInvalido: 'CPF inválido',
    email: 'E-mail',
    emailInvalido: 'E-mail inválido',
    envioImagens: 'Envio de imagens',
    exames: 'Exames',
    fechar: 'Fechar',
    laudo: 'Laudo',
    limpar: 'Limpar',
    nomeSistema: 'Sistema Integrado Catarinense de Telemedicina e Telessaúde',
    ok: 'OK',
    sair: 'Sair',
    erro: 'Erro',
    pesquisar: 'Pesquisar',
    pendencias: 'Pendências',
}

export default {
    UsuarioOnline: {
        atencao: stringsComuns.atencao,
        jaOnline: 'Você já está conectado no módulo de Teleatendimento através de outra aba, navegador ou dispotivo.'
    },
    Erros: {
        erro: stringsComuns.erro,
        naoAutorizado: 'Não autorizado'
    },
    ChatBottom: {
        enviarMensagem: 'Enviar mensagem',
        selecionarEmoji: 'Selecionar emoji',
        digiteMensagem: 'Digite sua mensagem...',
    },
    ChatTabList: {
        historicoChat: 'Histórico do chat',
        formatoData: 'DD/MM/YYYY'
    },
    ChatAtendente: {
        chat: 'Teleatendimento',
        chamadaVideo: 'Iniciar atendimento por vídeo',
        finalizarAtendimento: 'Finalizar atendimento',
        fecharChamadaVideo: 'Fechar atendimento por vídeo',
    },
    ChatAtendido: {
        chat: 'Teleatendimento',
        textoAguarde: 'Aguarde, em breve você será atendido.',
        textoAtendenteConectado: 'Atendente conectado, por favor informe sua dúvida.',
        textoAtendimentoFinalizado: 'O atendente encerrou este atendimento.',
    },
    ListaAnexos: {
        anexos: 'Lista de anexos',
        anexarArquivo: 'Anexar arquivos'
    },
    Anexo: {
        inserirAnexo: 'Inserir anexo',
        mensagemAnexo: 'Clique no botão ao lado para inserir um novo anexo',
        buscarAnexo: 'Buscar',
        observacao: 'Digite uma observação',
        anexar: 'Anexar',
        fechar: stringsComuns.fechar
    }
}