import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import detectBrowserLanguage from 'detect-browser-language';

const initializeApp = (config) => {
    const sentryUrl = 'https://a9e93e976a6c40efb4f38b9d22be7cfc@sentry.telessaude.ufsc.br/2';
    /**
     * SENTRY - https://docs.sentry.io/
     *
     * Inicialização do plugin
     */
    if (process.env.REACT_APP_SENTRY_RELEASE) {
        Sentry.init({
            dsn: sentryUrl,
            release: process.env.REACT_APP_SENTRY_RELEASE,
        });
    }

    /** Google Tag Manager */
    const tagManagerArgs = {
        gtmId: 'GTM-5G385ZS',
    };
    TagManager.initialize(tagManagerArgs);

    /*eslint-enable */
    global.gConfig = config;
    
    const DEFAULT_LANGUAGE = global.gConfig.default_language;
    const currentBrowserLanguage = detectBrowserLanguage();

    let currentLanguage = DEFAULT_LANGUAGE;
    if (currentBrowserLanguage.includes('en')) {
        currentLanguage = 'en';
    }
    currentLanguage = 'ptbr';
    
    document.getElementById('titulo_aplicacao').innerText = global.gConfig.instancia_nome;

    ReactDOM.render(<App currentLanguage={currentLanguage} />, document.getElementById('root'));
};

fetch(`${process.env.PUBLIC_URL}/config/config.json`, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((config) => initializeApp(config));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
