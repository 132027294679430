import TYPES from './types/helpdesk';

const INITIAL_STATE = {
    usuarioConversa: null,
    mensagemEnviar: null,
    openEnvioAnexos: false,
    mensagemEnviada: null,
    finalizarAtendimento: false,
    enviarSignalAtualizarAnexos: false,
    atualizarListaAnexos: false,
    notificarV4h: false,
    fecharV4h: false,
    entrarSalaVideo: false,
    filesOpen: false,
    chatOpen: true,
    realizarChamadaVideo: false,
};

const helpdesk = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TYPES.USUARIO_DESCONECTADO:
            return {
                ...state,
                usuarioConversa: null
            }
        case TYPES.INICIAR_ATENDIMENTO:
        case TYPES.USUARIO_ATENDENTE:
            return {
                ...state,
                usuarioConversa: payload.usuario
            }
        case TYPES.ENVIAR_MENSAGEM:
            return {
                ...state,
                mensagemEnviar: payload.mensagem
            }
        case TYPES.FINALIZAR_ATENDIMENTO:
            return {
                ...state,
                finalizarAtendimento: payload.finalizar,
            }
        case TYPES.MENSAGEM_ENVIADA:
            return {
                ...state,
                mensagemEnviada: payload.mensagem
            }
        case TYPES.OPEN_ENVIO_ANEXOS:
            return {
                ...state,
                openEnvioAnexos: true,
            };
        case TYPES.ATUALIZAR_LISTA_ANEXOS:
            return {
                ...state,
                atualizarListaAnexos: payload.atualizarListaAnexos,
            };
        case TYPES.ENVIAR_SIGNAL_ATUALIZAR_ANEXOS:
            return {
                ...state,
                enviarSignalAtualizarAnexos: payload.enviarSignalAtualizarAnexos,
            };
        case TYPES.CLOSE_ENVIO_ANEXOS:
            return {
                ...state,
                openEnvioAnexos: false,
            };
        case TYPES.ENVIAR_SIGNAL_ENTRAR_SALA_V4H:
            return {
                ...state,
                notificarV4h: payload.notifica,
            };
        case TYPES.ENVIAR_SIGNAL_FECHAR_SALA_V4H:
            return {
                ...state,
                fecharV4h: payload.notifica,
            };
        case TYPES.ENTRAR_SALA_V4H:
            return {
                ...state,
                entrarSalaVideo: payload.entrar,
            };
        case TYPES.ABRIR_FECHAR_ANEXOS:
            return {
                ...state,
                filesOpen: payload.open,
            };
        case TYPES.ABRIR_FECHAR_CHAT:
            return {
                ...state,
                chatOpen: payload.open,
            };
        case TYPES.REALIZAR_CHAMADA_VIDEO:
            return {
                ...state,
                realizarChamadaVideo: payload.chamada,
            };
        default:
            return state;
    }
}

export default helpdesk;
