import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: '150px'
    }
}));
const Aguarde = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Typography align="center" variant="h2">
                Parece que você chegou cedo. Aguarde o horário do seu atendimento.
            </Typography>
        </div>
    )
}

export default Aguarde;