import { combineReducers } from 'redux';

import TYPES from './types';
import helpdesk from './helpdesk';
const INITIAL_STATE = {
    user: {
        name: '',
        userId: ''
    }
};

const index = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
                case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }
        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
    helpdesk
});
