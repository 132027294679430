import moment from 'moment';

const chatHistoricoHandler = (axios, urlBase, patientId, partition, chatHistorico, callback, textoPaciente) => {
    const id = encodeURIComponent(patientId);

    axios.get(`${urlBase}/chat/${id}/${partition}`)
        .then((response) => {
            if (response.data.length > 0) {
                const historico = [];
                response.data.forEach((atendimento) => {
                    const dateAtendimento = new Date(atendimento.timestamp);
                    dateAtendimento.setHours(0, 0, 0, 0);
                    const index = historico.findIndex((h) => {
                        const dateH = new Date(h.timestamp)
                        dateH.setHours(0, 0, 0, 0);
                        return dateH.getTime() === dateAtendimento.getTime();
                    });

                    const dataAtendimento = moment(atendimento.timestamp).format('HH:mm - DD/MM/YYYY');
                    const dataFimAtendimento = moment(atendimento.endTimestamp).format('HH:mm - DD/MM/YYYY');

                    if (index == -1) {
                        historico.push({
                            timestamp: atendimento.timestamp,
                            mensagens: [{
                                ehSeparador: true,
                                atendimento: true,
                                texto: `Atendimento iniciado às ${dataAtendimento}`
                            }]
                                .concat(atendimento.chat)
                                .concat({
                                    ehSeparador: true,
                                    atendimento: true,
                                    texto: `Atendimento finalizado às ${dataFimAtendimento}`
                                })
                        });
                    } else {
                        historico[index].mensagens = [{
                            ehSeparador: true,
                            atendimento: true,
                            texto: `Atendimento iniciado às ${dataAtendimento}`
                        }]
                            .concat(atendimento.chat)
                            .concat({
                                ehSeparador: true,
                                atendimento: true,
                                texto: `Atendimento finalizado às ${dataFimAtendimento}`
                            })
                            .concat(historico[index].mensagens);
                    }
                });


                const dateHoje = new Date(chatHistorico[0].timestamp);
                dateHoje.setHours(0, 0, 0, 0);
                const dateUltimoAtendimento = new Date(historico[0].timestamp);
                dateUltimoAtendimento.setHours(0, 0, 0, 0);

                let historicoFinal;

                if (dateHoje.getTime() == dateUltimoAtendimento.getTime()) {
                    historicoFinal = historico;
                } else {
                    historicoFinal = chatHistorico.concat(historico);
                }

                const ultimaMsg = historicoFinal[0].mensagens[historicoFinal[0].mensagens.length - 1];
                if (ultimaMsg && ultimaMsg.ehSeparador) {
                    historicoFinal[0].mensagens.splice(-1, 1);
                }

                if (textoPaciente) {
                    historicoFinal[0].mensagens.push({
                        ehSeparador: true,
                        atendimento: false,
                        texto: textoPaciente
                    });
                }
                callback(historicoFinal);
            }
        });
};

/**
 * @param {String} role Practitioner | Patient
 * @return boolen
 */
const isPractitioner = (role) => {
    return role === 'Practitioner';
};

/**
 * @param {String} role Practitioner | Patient
 * @return boolen
 */
const isPatient = (role) => {
    return role === 'Patient';
};

export default {
    chatHistoricoHandler,
    isPractitioner,
    isPatient
};