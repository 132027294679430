import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from '@material-ui/lab/TabPanel';
import {
    SttMensagem,
    SttMensagemAviso
} from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    panel: {
        overflowY: 'auto',
        padding: theme.spacing(1)
    }
}));

const ChatTab = ({ userId, index, mensagens, chatRefs }) => {
    const classes = useStyles();

    return (
        <TabPanel className={classes.panel} key={index} value={String(index)} ref={mensagens => chatRefs.current[index] = mensagens} >
            {
                mensagens.map((item, index2) => {
                    if (item.ehSeparador) {
                        return <SttMensagemAviso key={index2} mensagem={item.texto} atendimento={item.atendimento} />;
                    }
                    const proprioUsuarioEnvio = item.author && item.author.id === userId;
                    const mensagemAnterior = mensagens[index2 - 1];
                    const esconderNomeEnviador = mensagemAnterior && item.author && mensagemAnterior.author && mensagemAnterior.author.id === item.author.id;
                    const properties = { ...item, usuario: { ...item.author } };
                    return <SttMensagem key={index2} {...properties} proprioUsuarioEnvio={proprioUsuarioEnvio} esconderNomeEnviador={esconderNomeEnviador} />;
                })
            }
        </TabPanel>
    );
};

export default ChatTab;