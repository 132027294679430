import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    setNotificarV4h,
    setEntrarSalaVideo,
    setChatOpen
} from '../reducers/actions/helpdesk';
import Functions from '../common/Functions';
import SessionManager from 'wise-api/dist/index';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        bottom: 0,
        top: 0,
        height: '100vh',
        width: (props) => (props.width),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: 0,
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        ...theme.palette.paper
    },
    meet: {
        height: '100%',
        width: '100%'
    }
}));

const buttons = [
    'microphone', 'camera', 'fodeviceselection', 'etherpad', 'settings', 'videoquality', 'filmstrip', 'shortcuts', 'tileview', 'help'
];

const v4h = new SessionManager();

const VideoForHealth = ({ user, setEntrarSalaVideo, setNotificarV4h, entrarSalaVideo, chatOpen, filesOpen, setChatOpen }) => {
    let width = '50%';
    if (!chatOpen && !filesOpen) {
        width = '100%';
    } else if (!chatOpen || !filesOpen) {
        width = '75%';
    }
    const classes = useStyles({ width });
    const meetRef = useRef(null);

    const { role, name } = user;

    const v4hScreen = (isPractitioner) => {

        if (isPractitioner) {
            buttons.push('recording', 'mute-everyone');
        }

        const options = {
            parentNode: document.getElementById('meet'),
            userInfo: {
                displayName: name
            },
            buttons: buttons,
            startWithAudioMuted: true
        }
        v4h.setup({
            login: global.gConfig.api_v4h_login,
            senha: global.gConfig.api_v4h_senha,
            domain: global.gConfig.api_v4h_domain,
            baseUrl: global.gConfig.api_v4h_base_url,
        }).then(() => {
            v4h.room.join(`Appointment${user.appointmentId}${user.encounterId.replace('/', '')}`).then((response) => {
                console.log(response);
                v4h.session.startConference(response.sessionId, options).then(() => {
                    setChatOpen();
                    setNotificarV4h(true);
                    setTimeout(() => {
                        meetRef.current.firstChild.style.height = '100%';
                        meetRef.current.firstChild.style.width = '100%';
                    }, 1000);
                });
            });

        }).catch(err => {
            console.log('DEU ERRO NO V4H');
            console.log(err);
        });
    }

    useEffect(() => {
        // ATENDENTE INICIA A CHAMADA NO V4H
        if (Functions.isPractitioner(role)) {
            v4hScreen(true);
        }

        return () => {
            v4h.setup({
                login: global.gConfig.api_v4h_login,
                senha: global.gConfig.api_v4h_senha,
                domain: global.gConfig.api_v4h_domain,
                baseUrl: global.gConfig.api_v4h_base_url,
            }).then(() => {
                v4h.session.stopConference().then(() => {
                    console.log("conferência finalizada");
                });
            }).catch(err => {
                console.log('DEU ERRO NO FINALIZAR CONFERÊNCIA NO V4H');
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (entrarSalaVideo) {
            v4hScreen(false);
            setEntrarSalaVideo();
        }
    }, [entrarSalaVideo]);

    return (
        <div className={classes.videoContainer}>
            <div id="meet" className={classes.meet} ref={meetRef}></div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filesOpen: state.helpdesk.filesOpen,
        chatOpen: state.helpdesk.chatOpen,
        entrarSalaVideo: state.helpdesk.entrarSalaVideo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEntrarSalaVideo: () => dispatch(setEntrarSalaVideo(false)),
        setNotificarV4h: (notifica) => dispatch(setNotificarV4h(notifica)),
        setChatOpen: () => dispatch(setChatOpen(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoForHealth);