import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import translate from '../../componentes/translate';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';
import CallEndIcon from '@material-ui/icons/CallEnd';
import TabContext from '@material-ui/lab/TabContext';
import Drawer from '@material-ui/core/Drawer';
import Functions from '../../common/Functions';
import { SttHeading } from '@stt-componentes/core';
import {
    setFinalizarAtendimento,
    setRealizarChamadaVideo,
    setFecharV4h,
    setChatOpen
} from '../../reducers/actions/helpdesk';
import ChatTab from '../../componentes/chat/chat-tab';
import ChatTabList from '../../componentes/chat/chat-tab-list';
import ChatBottom from '../../componentes/chat/chat-bottom';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    grow: {
        // Talvez possa remover quando incluir o input de busca
        flexGrow: 1,
    },
    drawer: {
        width: (props) => (props.chatOpen ? '25%' : '0'),
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    openChatButton: {
        padding: theme.spacing(1.5),
        position: 'absolute',
        top: 0,
        zIndex: 10,
        borderRadius: '0 0 15px 0',
        ...theme.palette.icon_button,
        ...theme.palette.floating_button,
    },
    drawerPaper: {
        width: '25%',
        top: 0,
        bottom: 0,
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            width: '100%'
        },
        ...theme.palette.paper
    },
    svgIcon: {
        ...theme.palette.svg_icon
    },
    iconButton: {
        marginLeft: '5px',
        ...theme.palette.icon_button
    },
    appBar: {
        ...theme.palette.app_bar
    },
    title: {
        padding: theme.spacing(0, 0, 0, 2),
        ...theme.palette.color_typo_12346
    },
}));

const chatInicial = [{
    timestamp: new Date().setHours(0, 0, 0, 0),
    mensagens: [],
}];

const ChatAtendente = ({ strings, userId, patientId, partition, mensagemEnviada, setFinalizarAtendimento, setChatOpen, chatOpen, realizarChamadaVideo, setRealizarChamadaVideo, setFecharV4h }) => {
    const classes = useStyles({ chatOpen });

    const chatRefs = useRef([]);
    const [chatAtivo, setChatAtivo] = useState(0);
    const [chatHistorico, setChatHistorico] = useState(chatInicial);

    const handleChangeTab = (event, newValue) => {
        setChatAtivo(newValue);
    };

    const finalizarAtendimento = () => {
        const chats = [...chatInicial];
        chatHistorico[0].mensagens = [];
        setChatHistorico(chats);
        setFinalizarAtendimento();
    }

    const openChat = () => {
        setChatOpen(!chatOpen);
    };

    const iniciarChamadaVideo = () => {
        setRealizarChamadaVideo(true);
    }

    const fecharChamadaVideo = () => {
        setRealizarChamadaVideo(false);
        setFecharV4h(true);
    }

    useEffect(() => {
        if (patientId) {
            Functions.chatHistoricoHandler(axios, global.gConfig.url_base_backend, patientId, partition, chatHistorico, setChatHistorico);
        }
    }, [patientId]);

    useEffect(() => {
        if (chatRefs.current[chatAtivo]) {
            chatRefs.current[chatAtivo].scrollTop = chatRefs.current[chatAtivo].scrollHeight;
        }
    }, [chatAtivo]);

    useEffect(() => {
        chatRefs.current = chatRefs.current.slice(0, chatHistorico.length);
        chatRefs.current.map((chat) => {
            chat.scrollTop = chat.scrollHeight;
        });
    }, [chatHistorico]);

    useEffect(() => {
        if (mensagemEnviada) {
            const chats = [...chatHistorico];
            const chatAtual = { ...chatHistorico[0] };
            chatAtual.mensagens.push(mensagemEnviada);
            setChatHistorico(chats);
            setChatAtivo(0);
        }
    }, [mensagemEnviada]);

    return (
        <React.Fragment>
            <IconButton disableRipple={true} className={classes.openChatButton} onClick={openChat}>
                <ChatIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={chatOpen}
                classes={{
                    paper: classes.drawerPaper,
                }} >
                <TabContext value={String(chatAtivo)}>
                    <AppBar position="sticky" className={classes.appBar}>
                        <Toolbar>
                            <ChatIcon className={classes.svgIcon} />
                            <SttHeading variant="h2" className={classes.title}>
                                {strings.chat}
                            </SttHeading>
                            <div className={classes.grow} />
                            {
                                !realizarChamadaVideo ?
                                    <Tooltip title={strings.chamadaVideo}>
                                        <IconButton disableRipple={true} edge="end" onClick={iniciarChamadaVideo} className={classes.iconButton}>
                                            <VideoCallIcon />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip title={strings.fecharChamadaVideo}>
                                        <IconButton disableRipple={true} edge="end" onClick={fecharChamadaVideo} className={classes.iconButton}>
                                            <VideocamOffIcon />
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip title={strings.finalizarAtendimento}>
                                <IconButton disableRipple={true} edge="end" onClick={finalizarAtendimento} className={classes.iconButton}>
                                    <CallEndIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton disableRipple={true} edge="end" className={classes.iconButton} onClick={openChat}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                        <ChatTabList handleChangeTab={handleChangeTab} chatHistorico={chatHistorico} />
                    </AppBar>
                    {chatHistorico.map(({ mensagens }, index) => (
                        <ChatTab mensagens={mensagens} index={index} chatRefs={chatRefs} userId={userId} key={index} />
                    ))}
                    <div className={classes.offset} />
                </TabContext>
                <ChatBottom setChatAtivo={setChatAtivo} />
            </Drawer>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.index.user.userId,
        patientId: state.index.user.patientId,
        partition: state.index.user.partition,
        mensagemEnviada: state.helpdesk.mensagemEnviada,
        chatOpen: state.helpdesk.chatOpen,
        realizarChamadaVideo: state.helpdesk.realizarChamadaVideo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFinalizarAtendimento: () => dispatch(setFinalizarAtendimento(true)),
        setRealizarChamadaVideo: (status) => dispatch(setRealizarChamadaVideo(status)),
        setFecharV4h: (notificar) => dispatch(setFecharV4h(notificar)),
        setChatOpen: (open) => dispatch(setChatOpen(open))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(translate('ChatAtendente')(ChatAtendente));