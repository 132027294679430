import React, { useState } from 'react';
import { connect } from 'react-redux';
import TYPES from '../../reducers/types/helpdesk';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import translate from './../translate';
import {
    ThemeProvider,
    createMuiTheme
} from "@material-ui/core/styles";
import {
    SttModal,
    SttButton,
    SttGrid,
    SttBasicFileSelect,
    SttLoading
} from '@stt-componentes/core';
import {
    setAtualizarAnexos,
    setEnviarSignalAtualizarAnexos
} from '../../reducers/actions/helpdesk';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    inputFile: {
        display: 'none'
    }
}));

const temaModal = createMuiTheme({
    palette: {
        primary: {
            main: '#0A6FB8'
        },
        secondary: {
            main: '#4A96D2'
        },
        error: {
            main: '#F5222D'
        },
        success: {
            main: '#52C41A'
        },
        text: {
            primary: '#4F4F4F',
            secondary: '#828282'
        },
        background: {
            default: "#FBFBFB",
            border: "#D9D9D9"
        }
    },
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'Roboto',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 14
    },
    props: {
        MuiTypography: {
            variantMapping: {
                body2: 'span',
            },
        },
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: '1.7rem',
                lineHeight: '130%',
                fontWeight: 600,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.5rem',
                    textAlign: 'center'
                }
            },
            h2: {
                fontSize: '1.5rem',
                lineHeight: '150%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.2rem',
                    textAlign: 'center'
                }
            },
            h3: {
                fontSize: '1.2rem',
                lineHeight: '160%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontWeight: 600,
                    textAlign: 'center'
                }
            },
            h4: {
                fontSize: '1.1rem',
                lineHeight: '160%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.1rem',
                    textAlign: 'center'
                },
            },
            h5: {
                fontSize: '0.9rem',
                lineHeight: '100%',
                fontWeight: 600
            },
            h6: {
                fontSize: '0.8rem',
                lineHeight: '150%',
                fontWeight: 600
            },
        }
    }
});

const Anexo = ({ strings, open, handleOpenInserirAnexo, user, usuarioConversa, setAtualizarAnexos, setEnviarSignalAtualizarAnexos }) => {
    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const URL_BASE_BACKEND = global.gConfig.url_base_backend;

    const handleClose = () => {
        handleOpenInserirAnexo(!open);
    }

    const changeHandler = (event) => {
        if (!event) {
            setSelectedFile(null);
            return;
        }

        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const anexarArquivo = () => {
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('partition', user.partition);
        data.append('user', JSON.stringify(user));

        if (usuarioConversa.ehAtendente) {
            data.append('patientName', usuarioConversa.name);
        } else {
            data.append('patientName', user.name);
        }

        const url = `${URL_BASE_BACKEND}/file`;

        setIsUploading(true);
        axios.post(url, data, {
            onUploadProgress: (event) => {
                let progress = Math.round(
                    (event.loaded * 100) / event.total
                );

                setProgress(progress);
                console.log(`A imagem está ${progress}% carregada... `);
            },
        })
            .then((response) => {
                console.log('aquiii')
                if (response.data && response.data.sucesso) {
                    handleOpenInserirAnexo(!open);
                    setAtualizarAnexos();
                    setEnviarSignalAtualizarAnexos();
                }
            }).catch((response) => {
                console.log(response);
            })
            .finally(() => {
                setIsUploading(false);
                setProgress(0);
            });
    }

    return (
        <ThemeProvider theme={temaModal}>
            <SttModal
                title={strings.inserirAnexo}
                open={open}
                outModalClose={handleClose}
                iconClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div>
                        <div className={classes.form}>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} md={12}>
                                    <SttBasicFileSelect onFileChange={changeHandler} />
                                </SttGrid>
                            </SttGrid>
                        </div>
                    </div>
                }
                footer={
                    <div>
                        <SttButton disabled={!selectedFile || isUploading} variant="contained" color="primary" onClick={anexarArquivo}>
                            {strings.anexar}
                        </SttButton>
                        <SttButton variant="contained" color="primary" onClick={handleClose}>
                            {strings.fechar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={isUploading}
                text='Enviando arquivo...'
                progress={progress}
            />
        </ThemeProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        usuarioConversa: state.helpdesk.usuarioConversa,
        open: state.helpdesk.openEnvioAnexos,
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleOpenInserirAnexo: (open) => {
            dispatch({
                type: open ? TYPES.OPEN_ENVIO_ANEXOS : TYPES.CLOSE_ENVIO_ANEXOS,
                payload: {}
            });
        },
        setAtualizarAnexos: () => { dispatch(setAtualizarAnexos(true)) },
        setEnviarSignalAtualizarAnexos: () => { dispatch(setEnviarSignalAtualizarAnexos(true)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('Anexo')(withRouter(Anexo)));