import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { SttContainer } from '@stt-componentes/core';
import { EVENTOS_SOCKET } from '../../common/Constants';
import {
    setIniciarAtendimento,
    setEnviarMensagem,
    setMensagemEnviada,
    setUsuarioDesconetado,
    setFinalizarAtendimento,
    setAtualizarAnexos,
    setRealizarChamadaVideo,
    setFecharV4h,
    setEnviarSignalAtualizarAnexos,
    setNotificarV4h
} from '../../reducers/actions/helpdesk';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChatAtendente from './chat-atendente';
import ModalUsuarioOnline from '../../componentes/modal-usuario-online';
import Anexos from '../../componentes/anexos/lista-anexos';
import Video from '../../componentes/video';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        maxWidth: '100%',
        padding: 0
    },
}));

const Teleatendimento = ({ usuarioConversa, user, mensagemEnviar, setMensagemEnviada, setEnviarMensagem, notificarV4h, setIniciarAtendimento, setUsuarioDesconetado, setFinalizarAtendimento,
    finalizarAtendimento, setAtualizarAnexos, setEnviarSignalAtualizarAnexos, enviarSignalAtualizarAnexos, setRealizarChamadaVideo, realizarChamadaVideo, setFecharV4h, fecharV4h, setNotificarV4h }) => {
    const classes = useStyles();

    const socket = useRef();
    const [idSocket, setIdSocket] = useState('');
    const [usuarioJaOnline, setUsuarioJaOnline] = useState(false);

    useEffect(() => {
        if (enviarSignalAtualizarAnexos) {
            socket.current.emit(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS, {
                idSocket: usuarioConversa.idSocket
            });

            setEnviarSignalAtualizarAnexos();
        }
    }, [enviarSignalAtualizarAnexos]);

    useEffect(() => {
        if (mensagemEnviar) {
            const dados = {
                destinatario: usuarioConversa.idSocket,
                author: {
                    name: user.name,
                    id: user.userId
                },
                message: mensagemEnviar,
                timestamp: new Date().getTime()
            };
            socket.current.emit(EVENTOS_SOCKET.ENVIAR_MENSAGEM, dados);
            setEnviarMensagem();
            setMensagemEnviada(dados);
        }
    }, [mensagemEnviar]);

    useEffect(() => {
        if (notificarV4h) {
            const dados = {
                destinatario: usuarioConversa.idSocket
            };
            socket.current.emit(EVENTOS_SOCKET.ENVIAR_SIGNAL_ENTRAR_SALA_V4H, dados);
            setNotificarV4h(false);
        }
    }, [notificarV4h]);

    useEffect(() => {
        if (fecharV4h) {
            const dados = {
                destinatario: usuarioConversa.idSocket
            };
            socket.current.emit(EVENTOS_SOCKET.ENVIAR_SIGNAL_FECHAR_SALA_V4H, dados);
            setFecharV4h(false);
        }
    }, [fecharV4h]);

    useEffect(() => {
        if (finalizarAtendimento) {
            socket.current.emit(EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO);
            setFinalizarAtendimento();
            setRealizarChamadaVideo(false);
            setMensagemEnviada(null);
            setUsuarioDesconetado();
        }
    }, [finalizarAtendimento]);

    useEffect(() => {
        if (user) {
            const userToSend = {
                name: user.name,
                userId: user.userId,
                encounterId: user.encounterId,
                partition: user.partition,
                ehAtendente: true,
            };

            const options = { query: userToSend };
            if (global.gConfig.url_base_socket_path != '') {
                options.path = global.gConfig.url_base_socket_path;
            }
            socket.current = io.connect(global.gConfig.url_base_socket, options);

            //Armazenamento do id e polling se o mesmo for médico, a fim de conectar ao usuário paciente
            socket.current.on(EVENTOS_SOCKET.ID_SOCKET, (id) => {
                // console.log('voltou com id', id);
                setIdSocket(id);
                // console.log('================================');
            });

            socket.current.on(EVENTOS_SOCKET.LISTA_PARA_ATENDIMENTO, (lista) => {
                console.log('================================');
                console.log('lista de pacientes para atender');
                console.log(lista);

                if (!usuarioConversa) {
                    // Procura pelo paciente que deve ser atendido apenas quando não está em atendimento
                    lista.forEach((usuario) => {
                        if (usuario.userId === user.patientId) {
                            socket.current.emit(EVENTOS_SOCKET.INICIAR_ATENDIMENTO, {
                                idSocketAtendido: usuario.idSocket,
                                idSocketAtendente: idSocket,
                                encounterId: user.encounterId,
                                appointmentId: user.appointmentId,
                                patientName: usuario.name,
                                patientId: user.patientId
                            });
                            setIniciarAtendimento(usuario);
                        }
                    });
                }
            });

            socket.current.on(EVENTOS_SOCKET.MENSAGEM_ENVIADA, (dados) => {
                setMensagemEnviada(dados);
            });

            socket.current.on(EVENTOS_SOCKET.USUARIO_DESCONETADO, (data) => {
                setUsuarioDesconetado();
                setMensagemEnviada(null);
                setRealizarChamadaVideo(false);
            });

            //Evento recebido quando o usuário já está conectado em outra aba/navegador
            socket.current.on(EVENTOS_SOCKET.USUARIO_ONLINE, () => {
                setUsuarioJaOnline(true);
            });

            socket.current.on(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS, () => {
                setAtualizarAnexos();
            });
        }

    }, [user]);

    return (
        <SttContainer className={classes.root}>
            <CssBaseline />
            {
                <>
                    <ChatAtendente />
                    {
                        realizarChamadaVideo ? <Video /> : null
                    }
                    <Anexos />
                </>
            }
            <ModalUsuarioOnline online={usuarioJaOnline} />
        </SttContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        usuarioConversa: state.helpdesk.usuarioConversa,
        mensagemEnviar: state.helpdesk.mensagemEnviar,
        enviarSignalAtualizarAnexos: state.helpdesk.enviarSignalAtualizarAnexos,
        finalizarAtendimento: state.helpdesk.finalizarAtendimento,
        realizarChamadaVideo: state.helpdesk.realizarChamadaVideo,
        notificarV4h: state.helpdesk.notificarV4h,
        fecharV4h: state.helpdesk.fecharV4h,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIniciarAtendimento: (usuario) => dispatch(setIniciarAtendimento(usuario)),
        setMensagemEnviada: (mensagem) => dispatch(setMensagemEnviada(mensagem)),
        setEnviarMensagem: () => dispatch(setEnviarMensagem(null)),
        setUsuarioDesconetado: () => dispatch(setUsuarioDesconetado()),
        setEnviarSignalAtualizarAnexos: () => dispatch(setEnviarSignalAtualizarAnexos(false)),
        setAtualizarAnexos: () => dispatch(setAtualizarAnexos(true)),
        setFinalizarAtendimento: () => dispatch(setFinalizarAtendimento(false)),
        setRealizarChamadaVideo: (chamada) => dispatch(setRealizarChamadaVideo(chamada)),
        setNotificarV4h: (notificar) => dispatch(setNotificarV4h(notificar)),
        setFecharV4h: (notificar) => dispatch(setFecharV4h(notificar)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teleatendimento);