export const SITUACAO = {
    EM_ATENDIMENTO: 10
};

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO'
}

export const EVENTOS_SOCKET = {
    ATENDENTE_CONECTADO: 'atendente_conectado',
    ATUALIZAR_LISTA_ANEXOS: 'atualizar_lista_anexos',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    ENVIAR_SIGNAL_ENTRAR_SALA_V4H: 'enviar_signal_entrar_sala_v4h',
    ENVIAR_SIGNAL_FECHAR_SALA_V4H: 'enviar_signal_fechar_sala_v4h',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    ID_NOVO_ATENDIMENTO: 'id_movo_atendimento',
    ID_SOCKET: 'id_socket',
    INICIAR_ATENDIMENTO: 'iniciar_atendimento',
    LISTA_PARA_ATENDIMENTO: 'lista_para_atendimento',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    SIGNAL_FECHAR_SALA_V4H: 'signal_fechar_sala_v4h',
    SIGNAL_ENTRAR_SALA_V4H: 'signal_entrar_sala_v4h',
    USUARIO_DESCONETADO: 'usuario_desconectado',
    USUARIO_ONLINE: 'usuario_online',
    V4H_SESSION: 'v4h_session'
};